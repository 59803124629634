// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyD4fPAAZgnMNK1FKAeYlWajaGqFDkwC8HY",
  authDomain: "quizsapabap.firebaseapp.com",
  projectId: "quizsapabap",
  storageBucket: "quizsapabap.appspot.com",
  messagingSenderId: "972940189232",
  appId: "1:972940189232:web:4dacf8910dab6bac72d7f5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export { db, auth };