<template>
  <div v-if="loading" class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else>
    <QuizHeader
      :answeredQuestions="answeredQuestions"
      :totalQuestions="totalQuestions"
      :scorePercentage="scorePercentage"
    />
    <div class="container mt-5">
      <QuizQuestion
        ref="quizQuestion"
        v-if="currentQuestion"
        :question="currentQuestion"
        :savedResponses="selectedResponses[currentQuestionId] || []"
        :savedResult="results[currentQuestionId] || null"
        :savedDifficulty="difficulties[currentQuestionId] || ''"
        @updateScore="updateScore"
        @saveResponses="saveResponses"
        @saveDifficulty="saveDifficulty"
      />
      <div v-else-if="Object.keys(filteredQuestions).length > 0">
        <h2>Quiz Finalizado!</h2>
        <p>Seu percentual de acerto: {{ scorePercentage }}%</p>
        <button class="btn btn-secondary" @click="resetQuiz">Repetir Tudo</button>
        <button class="btn btn-secondary" @click="retryIncorrect">Repetir Incorretas</button>
      </div>
      <div v-if="currentQuestion" class="d-flex justify-content-between mt-3">
        <button class="btn btn-primary" @click="prevQuestion" :disabled="isFirstQuestion">Anterior</button>
        <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#goToQuestionModal">Avançar X</button>
        <button class="btn btn-primary" @click="nextQuestion">Próxima</button>
      </div>
    </div>
    <footer class="container mt-5">
      <button class="btn btn-info" data-bs-toggle="modal" data-bs-target="#quizParametersModal">Parâmetros do Quiz</button>
    </footer>

    <!-- Modal de Parâmetros do Quiz -->
    <QuizParametersModal
      :shuffleQuestions="shuffleQuestions"
      :selectedDifficulties="selectedDifficulties"
      @update:shuffleQuestions="updateShuffleQuestions"
      @update:selectedDifficulties="updateSelectedDifficulties"
      @resetQuiz="resetQuiz"
      @resetClassifications="resetClassifications"
    />

    <!-- Modal de Ir Para Pergunta -->
    <GoToQuestionModal
      :maxQuestions="Object.keys(filteredQuestions).length"
      @goToQuestion="goToQuestion"
    />
  </div>
</template>

<script>
import QuizHeader from './QuizHeader.vue';
import QuizQuestion from './QuizQuestion.vue';
import QuizParametersModal from './QuizParametersModal.vue';
import GoToQuestionModal from './GoToQuestionModal.vue';
import { onAuthStateChanged } from 'firebase/auth';
import { db, auth } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default {
  name: 'QuizApp',
  components: {
    QuizHeader,
    QuizQuestion,
    QuizParametersModal,
    GoToQuestionModal
  },
  data() {
    return {
      questions: {},
      filteredQuestions: {},
      currentQuestionId: null,
      selectedResponses: {},
      results: {},
      retries: [],
      difficulties: {},
      shuffleQuestions: false,
      selectedDifficulties: [],
      loading: true
    };
  },
  computed: {
    totalQuestions() {
      return Object.keys(this.filteredQuestions).length;
    },
    answeredQuestions() {
      return Object.values(this.results).filter(result => result !== undefined).length;
    },
    scorePercentage() {
      const correctAnswers = Object.values(this.results).filter(result => result).length;
      return Object.keys(this.results).length ? parseFloat(((correctAnswers / Object.keys(this.results).length) * 100).toFixed(2)) : 0;
    },
    currentQuestion() {
      return this.currentQuestionId ? this.filteredQuestions[this.currentQuestionId] : null;
    },
    isFirstQuestion() {
      return this.currentQuestionId === Object.keys(this.filteredQuestions)[0];
    }
  },
  watch: {
    shuffleQuestions() {
      this.loadQuestions();
    },
    selectedDifficulties() {
      this.applyFilters();
    }
  },
  methods: {
    updateShuffleQuestions(newValue) {
      this.shuffleQuestions = newValue;
      this.saveState();
    },
    updateSelectedDifficulties(newValues) {
      this.selectedDifficulties = Array.isArray(newValues) ? newValues : [];
      this.saveState();
    },
    updateScore(result) {
      this.results = { ...this.results, [this.currentQuestionId]: result };
      this.saveState();
    },
    saveResponses(responses) {
      this.selectedResponses = { ...this.selectedResponses, [this.currentQuestionId]: responses };
      this.saveState();
    },
    saveDifficulty(difficulty) {
      this.difficulties = { ...this.difficulties, [this.currentQuestionId]: difficulty };
      this.saveState();
    },
    checkCurrentAnswer() {
      if (this.results[this.currentQuestionId] === undefined && this.$refs.quizQuestion) {
        this.$refs.quizQuestion.checkAnswer();
      }
    },
    nextQuestion() {
      this.checkCurrentAnswer();
      const questionIds = Object.keys(this.filteredQuestions);
      const currentIndex = questionIds.indexOf(this.currentQuestionId);
      if (currentIndex < questionIds.length - 1) {
        this.currentQuestionId = questionIds[currentIndex + 1];
      }
      this.saveState();
    },
    prevQuestion() {
      this.checkCurrentAnswer();
      const questionIds = Object.keys(this.filteredQuestions);
      const currentIndex = questionIds.indexOf(this.currentQuestionId);
      if (currentIndex > 0) {
        this.currentQuestionId = questionIds[currentIndex - 1];
      }
      this.saveState();
    },
    resetQuiz() {
      this.currentQuestionId = Object.keys(this.filteredQuestions)[0];
      this.selectedResponses = {};
      this.results = {};
      this.retries = [];
      this.loadQuestions();
      this.saveState();
    },
    resetClassifications() {
      this.difficulties = {};
      this.saveState();
    },
    retryIncorrect() {
      this.filteredQuestions = Object.fromEntries(Object.entries(this.filteredQuestions).filter(([id]) => !this.results[id]));
      this.resetQuiz();
    },
    async loadQuestions() {
      try {
        const querySnapshot = await getDocs(collection(db, 'questions'));
        const questionsData = {};
        querySnapshot.forEach((doc) => {
          questionsData[doc.data().ID] = doc.data();
        });
        this.questions = this.shuffleQuestions ? this.shuffleObject(questionsData) : questionsData;
        this.filteredQuestions = { ...this.questions };
        this.loadState();
      } catch (error) {
        console.error('Erro ao carregar perguntas:', error);
      }
    },
    applyFilters() {
      if (this.selectedDifficulties.length === 0 || this.selectedDifficulties.length === 3) {
        this.filteredQuestions = { ...this.questions };
      } else {
        // eslint-disable-next-line
        this.filteredQuestions = Object.fromEntries(Object.entries(this.questions).filter(([id, question]) => this.difficulties[id] && this.selectedDifficulties.includes(this.difficulties[id])));
      }
      if (!this.filteredQuestions[this.currentQuestionId]) {
        this.currentQuestionId = Object.keys(this.filteredQuestions)[0];
      }
    },
    shuffleObject(obj) {
      const keys = Object.keys(obj);
      keys.sort(() => Math.random() - 0.5);
      return keys.reduce((result, key) => {
        result[key] = obj[key];
        return result;
      }, {});
    },
    saveState() {
      const state = {
        currentQuestionId: this.currentQuestionId,
        selectedResponses: this.selectedResponses,
        results: this.results,
        difficulties: this.difficulties,
        shuffleQuestions: this.shuffleQuestions,
        selectedDifficulties: this.selectedDifficulties
      };
      localStorage.setItem('quizState', JSON.stringify(state));
    },
    loadState() {
      const savedState = localStorage.getItem('quizState');
      if (savedState) {
        const { currentQuestionId, selectedResponses, results, difficulties, shuffleQuestions, selectedDifficulties } = JSON.parse(savedState);
        this.currentQuestionId = currentQuestionId;
        this.selectedResponses = selectedResponses;
        this.results = results;
        this.difficulties = difficulties;
        this.shuffleQuestions = shuffleQuestions;
        this.selectedDifficulties = selectedDifficulties;
      } else {
        this.currentQuestionId = Object.keys(this.filteredQuestions)[0];
      }
      this.applyFilters();
    },
    goToQuestion(offset) {
      const questionIds = Object.keys(this.filteredQuestions);
      const currentIndex = questionIds.indexOf(this.currentQuestionId);
      const newQuestionIndex = currentIndex + offset - 1;
      if (newQuestionIndex >= 0 && newQuestionIndex < questionIds.length) {
        this.currentQuestionId = questionIds[newQuestionIndex];
        this.saveState();
      }
    }
  },
  created() {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.loadQuestions();
      } else {
        this.$router.push('/login');
      }
      this.loading = false;
    });
  }
};
</script>
